exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-books-tsx": () => import("./../../../src/pages/books.tsx" /* webpackChunkName: "component---src-pages-books-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-freelance-tsx": () => import("./../../../src/pages/freelance.tsx" /* webpackChunkName: "component---src-pages-freelance-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-thoughts-tsx": () => import("./../../../src/pages/thoughts.tsx" /* webpackChunkName: "component---src-pages-thoughts-tsx" */),
  "component---src-templates-book-template-tsx": () => import("./../../../src/templates/BookTemplate.tsx" /* webpackChunkName: "component---src-templates-book-template-tsx" */),
  "component---src-templates-project-template-tsx": () => import("./../../../src/templates/ProjectTemplate.tsx" /* webpackChunkName: "component---src-templates-project-template-tsx" */),
  "component---src-templates-thought-template-tsx": () => import("./../../../src/templates/ThoughtTemplate.tsx" /* webpackChunkName: "component---src-templates-thought-template-tsx" */)
}

